//
//
//
//
//
//

import Login from '@/components/Authentication/Login'
export default {
    components: {
        Login
    },
    methods:{
        isLogin(){
            if(this.$store.state.isLoggedIn){
                this.$router.push('/home')
            }
        }
    },
    mounted(){
        this.$store.commit('hideLeftSidebar'),
        this.isLogin()
    },
    beforeDestroy(){
      this.$store.commit('displayLeftSidebar')
    },
    created(){
        
    }
}
