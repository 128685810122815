//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Login",
    data() {
        return {
            siEmailAdress   : "",
            siPassword      : "",
            isPwd           : true,
            siAccept        : false,
            onLoadAnimate: false,
            payload: [],
            invalidLogin: false,
            isSigningIn: false
        }
    },
    metaInfo:{
      title:'Login |'
    },
    methods: {
        onSubmit(){
          this.invalidLogin = false;
          this.isSigningIn = true;
          this.payload = {
            login_EmailAdress: this.siEmailAdress,
            login_Password: this.siPassword,
            login_Accept: this.siAccept
          }

          this.$store.dispatch('login',this.payload)
          .then((status)=>{ 
            this.isSigningIn = false;
            if(status.message == 'success') {
              this.$store.dispatch('user/setProfiledata', this.$store.state.userData)
              this.$router.push('/profile')
              return;
            } 
            if (status.message == 'activation_required') {
              this.$router.push({
                name: 'Activation',
                params: { 
                  email: status.email,
                  activation_key: status.key
                }
               }
              );
            }
          })
          .catch((err) => {
            this.isSigningIn = false;
            if (err.response.status == 404) {
              this.invalidLogin = true;
            }
          })
        },
        onReset(){          
        }
    },
    mounted(){
      this.onLoadAnimate = true;
    },
    beforeDestroy(){
      this.$store.commit('app/lightmode')
    },
}
